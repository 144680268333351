.assign-menu__user-list__item-enter {
  opacity: 0;
  max-height: 0;
}

.assign-menu__user-list__item-enter-active {
  opacity: 1;
  max-height: 100px;
  transition: opacity 500ms, max-height 500ms;
}

.assign-menu__user-list__item-exit {
  opacity: 1;
  max-height: 100px;
}

.assign-menu__user-list__item-exit-active {
  opacity: 0;
  max-height: 0;
  transition: opacity 500ms, max-height 500ms;
}
