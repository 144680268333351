.list-notes-section-enter {
  opacity: 0;
  max-height: 0;
}

.list-notes-section-enter-active {
  opacity: 1;
  max-height: 500px;
  transition: opacity 500ms, max-height 500ms;
}

.list-notes-section-exit {
  opacity: 1;
  max-height: 500px;
}

.list-notes-section-exit-active {
  opacity: 0;
  max-height: 0;
  transition: opacity 500ms, max-height 500ms;
}
