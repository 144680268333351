.visibility-hidden {
  visibility: hidden;
}

.visibility-visible {
  visibility: visible;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block;
}

.disable-pointer-events {
  pointer-events: none;
}

.enable-pointer-events {
  pointer-events: initial;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-appearance-only {
  opacity: 0.5;
}

.clickable {
  text-decoration: none;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
