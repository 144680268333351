ion-button.instructions-link_action-button::part(native) {
  align-items: start;
}

.instructions-link_save-button {
  --border-radius: 2rem;
  min-height: 0px;
  --padding-start: 1.25em;
  --padding-end: 1.25em;
  --padding-top: 0.75rem;
  --padding-bottom: 0.75rem;
}
