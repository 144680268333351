.no-vertical-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.no-padding-top {
  padding-top: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.no-horizontal-padding {
  padding-right: 0;
  padding-left: 0;
}

.no-padding-start {
  padding-inline-start: 0;
}

.no-padding-end {
  padding-inline-end: 0;
}

.padding-start {
  padding-inline-start: 10px;
}

.padding-end {
  padding-inline-end: 10px;
}

.no-vertical-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.small-margin-bottom {
  margin-bottom: calc(16 / var(--root-size) * 1rem) !important;
}

.no-horizontal-margin {
  margin-left: 0;
  margin-right: 0;
}

.no-margin-start {
  margin-inline-start: 0;
}

.no-margin-end {
  margin-inline-end: 0;
}

.vertical-padding-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
