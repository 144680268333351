/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

@font-face {
  font-family: 'BalooThambi2';
  src: url('../assets/fonts/BalooThambi2-VariableFont_wght.ttf');
  font-weight: 100 1000;
}

:root,
:root[mode='ios'],
:root[mode='md'] {
  --ion-font-family: 'BalooThambi2';
  font-family: 'BalooThambi2', serif;
}

/** Ionic CSS Variables **/
:root {
  /*unit-less for proper use of calc function*/
  --root-size: 16;

  --ion-background-color: #fcfbfe;
  --ion-background-color-rgb: rgb(252, 251, 254);

  --ion-text-color-rgb: 25, 28, 30;
  --ion-text-color: rgba(var(--ion-text-color-rgb), 0.87);

  /** for modifying these colors, see here: https://ionicframework.com/docs/theming/colors#modifying-colors **/
  /** make sure to also modify the dark mode version of the color in the Dark Colors section below **/
  /** primary **/
  --ion-color-primary: #006c45;
  --ion-color-primary-rgb: 0, 108, 69;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #005f3d;
  --ion-color-primary-tint: #1a7b58;

  /** secondary **/
  --ion-color-secondary: #4c6455;
  --ion-color-secondary-rgb: 76, 100, 85;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #43584b;
  --ion-color-secondary-tint: #5e7466;

  /** tertiary **/
  --ion-color-tertiary: #386473;
  --ion-color-tertiary-rgb: 56, 100, 115;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #315865;
  --ion-color-tertiary-tint: #4c7481;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ba1a1a;
  --ion-color-danger-rgb: 186, 26, 26;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #a41717;
  --ion-color-danger-tint: #c13131;

  --ion-color-error: #ff5449;
  --ion-color-error-rgb: 255, 84, 73;
  --ion-color-error-contrast: #000000;
  --ion-color-error-contrast-rgb: 0, 0, 0;
  --ion-color-error-shade: #e04a40;
  --ion-color-error-tint: #ff655b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  /* --ion-color-light: #dee2ee; */
  --ion-color-light: rgba(25, 28, 30, 0.38);
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** for adding custom colors, see here: https://ionicframework.com/docs/theming/colors#adding-colors **/
  /** make sure to also add the dark mode version of the color in the Dark Colors section below **/
  /** custom colors **/
  --ion-color-custom-overlay: #e0ebea;
  --ion-color-custom-overlay-rgb: 224, 235, 234;
  --ion-color-custom-overlay-contrast: #000000;
  --ion-color-custom-overlay-contrast-rgb: 0, 0, 0;
  --ion-color-custom-overlay-shade: #c5cfce;
  --ion-color-custom-overlay-tint: #e3edec;

  --ion-color-custom-gray: #74787a;
  --ion-color-custom-gray-rgb: 116, 120, 122;
  --ion-color-custom-gray-contrast: #ffffff;
  --ion-color-custom-gray-contrast-rgb: 255, 255, 255;
  --ion-color-custom-gray-shade: #666a6b;
  --ion-color-custom-gray-tint: #828687;

  /** custom overlay background **/
  --ion-color-overlay: #dce3e9;
  --ion-color-overlay-rgb: 220, 227, 233;
  --ion-color-overlay-contrast: #000000;
  --ion-color-overlay-contrast-rgb: 0, 0, 0;
  --ion-color-overlay-shade: #c2c8cd;
  --ion-color-overlay-tint: #e0e6eb;

  --button-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.85);
  --button-font-size: calc(19 / var(--root-size) * 1rem);
  --button-font-weight: 500;
  --button-border-radius: 4px 36px 4px 36px;
  --button-circle-radius: 50%;
  --button-height: calc(48 / var(--root-size) * 1rem);
  --button-min-width: calc(82 / var(--root-size) * 1rem);

  --toolbar-height: calc(64 / var(--root-size) * 1rem);
  --toolbar-background: var(--ion-color-custom-overlay);

  --ion-label-font-size: calc(20 / var(--root-size) * 1rem) !important;
  /*--ion-label-color: var(--ion-text-color);*/

  /** custom miscellaneous variables **/
  /* TODO: Thesse Styles look like they should be moved into a global style rule */
  --checkbox-size: calc(34 / var(--root-size) * 1rem);
  /** image used to mark item done/complete **/
  --ct_checkmark-image: url('../assets/images/icons/Checkmark_White.svg');

  /* searchbar background color */
  --ion-custom-searchbar-background: #e6ebed;
  --ion-custom-searchbar-background-rgb: 230, 235, 237;
  --ion-custom-searchbar-background-contrast: #000000;
  --ion-custom-searchbar-background-contrast-rgb: 0, 0, 0;
  --ion-custom-searchbar-background-shade: #cacfd1;
  --ion-custom-searchbar-background-tint: #e9edef;
}

/*button variables*/

/*
 * Dark Colors
 * -------------------------------------------
 */

body.dark {
  --ion-background-color: #191c1e;
  --ion-background-color-rgb: rgb(25, 28, 30);

  --ion-text-color-rgb: 255, 255, 255;
  --ion-text-color: rgba(var(--ion-text-color-rgb), 0.87);

  /** for modifying these colors, see here: https://ionicframework.com/docs/theming/colors#modifying-colors **/
  /** make sure to also modify the light mode version of the color in the Ionic CSS Variables section above **/
  --ion-color-primary: #6adca2;
  --ion-color-primary-rgb: 106, 220, 162;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #5dc28f;
  --ion-color-primary-tint: #79e0ab;

  --ion-color-secondary: #4e616c;
  --ion-color-secondary-rgb: 78, 97, 108;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #45555f;
  --ion-color-secondary-tint: #60717b;

  --ion-color-tertiary: #5f5a7d;
  --ion-color-tertiary-rgb: 95, 90, 125;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #544f6e;
  --ion-color-tertiary-tint: #6f6b8a;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ba1a1a;
  --ion-color-danger-rgb: 186, 26, 26;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #a41717;
  --ion-color-danger-tint: #c13131;

  --ion-color-error: #ff5449;
  --ion-color-error-rgb: 255, 84, 73;
  --ion-color-error-contrast: #000000;
  --ion-color-error-contrast-rgb: 0, 0, 0;
  --ion-color-error-shade: #e04a40;
  --ion-color-error-tint: #ff655b;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: rgba(255, 255, 255, 0.38);
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --ion-color-custom-color: #a5dfff;
  --ion-color-custom-color-rgb: 165, 223, 255;
  --ion-color-custom-color-contrast: #003549;
  --ion-color-custom-color-contrast-rgb: 0, 53, 73;
  --ion-color-custom-color-shade: #91c4e0;
  --ion-color-custom-color-tint: #aee2ff;

  /** for adding custom colors, see here: https://ionicframework.com/docs/theming/colors#adding-colors **/
  /** make sure to also add the light mode version of the color in the Ionic CSS Variables section above **/
  /** custom colors **/
  --ion-color-custom-overlay: #1e2529;
  --ion-color-custom-overlay-rgb: 30, 37, 41;
  --ion-color-custom-overlay-contrast: #ffffff;
  --ion-color-custom-overlay-contrast-rgb: 255, 255, 255;
  --ion-color-custom-overlay-shade: #1a2124;
  --ion-color-custom-overlay-tint: #353b3e;

  /** custom overlay background **/
  --ion-color-overlay: #404943;
  --ion-color-overlay-rgb: 64, 73, 67;
  --ion-color-overlay-contrast: #ffffff;
  --ion-color-overlay-contrast-rgb: 255, 255, 255;
  --ion-color-overlay-shade: #38403b;
  --ion-color-overlay-tint: #535b56;

  /* TODO: These look like global css rules */
  --button-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.7);

  --toolbar-background: var(--ion-color-custom-overlay);
  /** image used to mark item done/complete **/
  --ct_checkmark-image: url('../assets/images/icons/Checkmark_Black.svg');

  /* searchbar background color */
  --ion-custom-searchbar-background: #41484d;
  --ion-custom-searchbar-background-rgb: 65, 72, 77;
  --ion-custom-searchbar-background-contrast: #ffffff;
  --ion-custom-searchbar-background-contrast-rgb: 255, 255, 255;
  --ion-custom-searchbar-background-shade: #393f44;
  --ion-custom-searchbar-background-tint: #545a5f;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
  /*#74787A*/
  --ion-background-color: #191c1e;
  --ion-background-color-rgb: 25, 28, 30;

  --ion-text-color-rgb: 255, 255, 255;
  --ion-text-color: rgba(var(--ion-text-color-rgb), 0.87);

  --ion-color-step-50: #252729;
  --ion-color-step-100: #303335;
  --ion-color-step-150: #3c3e40;
  --ion-color-step-200: #47494b;
  --ion-color-step-250: #535556;
  --ion-color-step-300: #5e6062;
  --ion-color-step-350: #6a6b6d;
  --ion-color-step-400: #757778;
  --ion-color-step-450: #818283;
  --ion-color-step-500: #8c8e8f;
  --ion-color-step-550: #98999a;
  --ion-color-step-600: #a3a4a5;
  --ion-color-step-650: #afb0b0;
  --ion-color-step-700: #babbbc;
  --ion-color-step-750: #c6c6c7;
  --ion-color-step-800: #d1d2d2;
  --ion-color-step-850: #dddddd;
  --ion-color-step-900: #e8e8e9;
  --ion-color-step-950: #f4f4f4;

  --ion-item-background: var(--ion-background-color);

  --ion-card-background: #1c1c1d;
}

/* TODO: Global CSS Rules */
.ios ion-modal.dark {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
}

.ios ion-toolbar {
  --border-color: transparent;
}

ion-toolbar.no-background {
  --background: transparent;
}

/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
  --ion-background-color: #191c1e;
  --ion-background-color-rgb: 25, 28, 30;

  --ion-text-color-rgb: 255, 255, 255;
  --ion-text-color: rgba(var(--ion-text-color-rgb), 0.87);

  --ion-color-step-50: #252729;
  --ion-color-step-100: #303335;
  --ion-color-step-150: #3c3e40;
  --ion-color-step-200: #47494b;
  --ion-color-step-250: #535556;
  --ion-color-step-300: #5e6062;
  --ion-color-step-350: #6a6b6d;
  --ion-color-step-400: #757778;
  --ion-color-step-450: #818283;
  --ion-color-step-500: #8c8e8f;
  --ion-color-step-550: #98999a;
  --ion-color-step-600: #a3a4a5;
  --ion-color-step-650: #afb0b0;
  --ion-color-step-700: #babbbc;
  --ion-color-step-750: #c6c6c7;
  --ion-color-step-800: #d1d2d2;
  --ion-color-step-850: #dddddd;
  --ion-color-step-900: #e8e8e9;
  --ion-color-step-950: #f4f4f4;
}

/* Custom Colors Classes */

.ion-color-custom-overlay {
  --ion-color-base: var(--ion-color-custom-overlay);
  --ion-color-base-rgb: var(--ion-color-custom-overlay-rgb);
  --ion-color-contrast: var(--ion-color-custom-overlay-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-overlay-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-overlay-shade);
  --ion-color-tint: var(--ion-color-custom-overlay-tint);
}

.ion-color-custom-gray {
  --ion-color-base: var(--ion-color-custom-gray);
  --ion-color-base-rgb: var(--ion-color-custom-gray-rgb);
  --ion-color-contrast: var(--ion-color-custom-gray-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-gray-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-gray-shade);
  --ion-color-tint: var(--ion-color-custom-gray-tint);
}

.ion-color-error {
  --ion-color-base: var(--ion-color-error);
  --ion-color-base-rgb: var(--ion-color-error-rgb);
  --ion-color-contrast: var(--ion-color-error-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error-contrast-rgb);
  --ion-color-shade: var(--ion-color-error-shade);
  --ion-color-tint: var(--ion-color-error-tint);
}
