.transform-chevron {
  transition-duration: 100ms;
}

.rotate180 {
  transform: rotate(180deg);
}

.-rotate180 {
  transform: rotate(-180deg);
}

.transform-100 {
  transition: all 300ms cubic-bezier(0.25, 0.8, 0.5, 1) 0s;
}

.rotate45 {
  transform: rotate(45deg);
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: 0.5s opacity, 0.5s visibility;
}

.visible {
  opacity: 1;
  visibility: visible;
  transition: 0.5s opacity, 0.5s visibility;
}
